<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div />

          <div class="title">Sign Up</div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal()">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div style="padding: 23px 45px 5px 45px">
        <div><inline-svg src="/ch-signup.svg" class="w-100"></inline-svg></div>
        <div style="color: #431c89">
          <SignupForm
            :isModal="isOpen"
            :isClicked="isClicked"
            @registered="register"
            @loading="loadContent"
            @error="isClicked = false"
          />
        </div>
        <div class="p-2" style="color: #431c89">
          <SignupParagraphs :isModal="true" />
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="signUp" class="w-25" style="--background: #ae38e5">
            <ChLoading size="sm" v-if="isloading" class="spinner" />
            <span v-else>Sign Up</span>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import SignupForm from '~/shared/pages/signup/components/SignupForm.vue';
import SignupParagraphs from '~/shared/pages/signup/components/SignupParagraphs.vue';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close', 'signUp']);
const isClicked = ref(false);
const isloading = ref(false);
const isOpen = toRef(props, 'isOpen');
const signUp = () => {
  if (isloading.value) return;
  isClicked.value = true;
};
const register = () => {
  emits('signUp');
};
const loadContent = (load: any) => {
  isloading.value = load;
};
const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #f2f2f2)
  max-height: 60vh
  overflow-x: hidden
  overflow-y: auto

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
