<template>
  <ion-modal class="modal-big" :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true">
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div />

          <div class="title">Event Rules</div>
          <div>
            <ion-buttons slot="end" v-if="!isEvent || isTermsAndConditions">
              <ion-button @click="closeModal()">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="inner-content">
      <div class="modal-inner-content p-3">
        <div class="desc" v-html="events.legal_terms" />
      </div>
      <div class="d-flex align-items-center" style="padding: 5px 0rem 5px 1rem" v-if="!isTermsAndConditions">
        <ion-checkbox mode="ios" v-model="ruleAgreement" class="mr-1"></ion-checkbox>
        <ion-label style="text-align: left">I read and agree with the Event Rules </ion-label>
      </div>
    </div>
    <ion-footer translucent v-if="!isTermsAndConditions">
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button :disabled="!ruleAgreement || isLoading" @click.prevent="submit">
            <ChLoading size="sm" v-if="isLoading" />
            <span v-else> {{ !isEvent ? 'Submit' : 'Sign Up' }}</span>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { siteEventTermsAndConditionAgreement } from '../actions/events';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  events: {
    type: Object,
    default: {},
  },
  isEvent: {
    type: Boolean,
    default: false,
  },
  isTermsAndConditions: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['close', 'cancel']);
const events = toRef(props, 'events');
const ruleAgreement = ref(false);
const isOpen = toRef(props, 'isOpen');
const isLoading = ref(false);

const dismissModal = () => {
  emits('close');
};
const submit = async () => {
  if (!ruleAgreement.value) return;
  try {
    isLoading.value = true;
    await siteEventTermsAndConditionAgreement(events.value.id);
  } catch (error) {
    return;
  } finally {
    isLoading.value = false;
    dismissModal();
  }
};
const closeModal = () => {
  emits('cancel');
};
</script>
<style lang="sass" scoped>
ion-checkbox
  background: #ffffff
  border-width: 1px !important
  border-style: solid !important
  --border-radius: 5px
  --checkmark-color: #fff
.dark .toolbar
  --background: #17074c !important
  color: white
.inner-content
  background: var(--ion-background-color, #f2f2f2)
.modal-inner-content
  background: var(--ion-background-color, #f2f2f2)
  max-height: 40vh
  overflow-x: hidden
  overflow-y: auto
  padding: 1rem 1rem 0rem 1rem

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #431C89
  font-size: 23px
  font-weight: bold
</style>
